<template>
	<el-container class="visual-base" style="height: 100vh">
		<el-header class="header">
			<div class="visual-starry-bg"></div>
			<!-- 公司logo -->
			<!-- <img src="../assets/images/iotech.png" /> -->
			<div class="weather">
				<el-select v-model="areaCode" filterable placeholder="请选择" @change="city" style="width:15%">
					<el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.code">
					</el-option>
				</el-select>
				<span>天气：
					<i :class="'qi-' + weather.icon"></i>
					{{ weather.text }}</span>
				<span>体感温度：{{ weather.feelsLike }}℃</span>
				<span>风向：{{ weather.windDir }}</span>
				<span>风速：{{ weather.windSpeed }} M/s</span>
				<span>风力等级：{{ weather.windScale }}级</span>
			</div>
			<div>
				<span class="users">您好！{{ users }}</span>
				<span v-if="fullscreen" @click="fullScroll" class="iconfont symbol">&#xe8fb;</span>
				<span v-else @click="fullScroll" class="iconfont symbol">&#xe8fa;</span>
			</div>

			<el-dropdown class="logout" trigger="click">
				<i class="el-icon-s-unfold icon"></i>
				<el-dropdown-menu>
					<el-dropdown-item>
						<div @click="changePasd">
							修改密码
						</div>
					</el-dropdown-item>
					<br />
					<el-dropdown-item>
						<div @click="logout">
							退出登录
						</div>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</el-header>

		<el-main>
			<!-- 菜单列表 -->
			<div class="container">
				<div class="companyName">
					<div class="title">{{ platformInfo.platformName }}</div>
					<div class="time">
						<div class="left">{{ hour }}</div>
						<div class="right">
							<div class="top">{{ year }}</div>
							<div class="btm">{{ week }}</div>
						</div>
					</div>
				</div>
				<!-- 菜单 -->
				<div class="menuList">
					<div class="list-item" v-for="(item, index) in userMenu" :key="index" ref="disp" :id="index">
						<div v-if="item.isShow" class="list-item1" ref="disp" @click="myDevice(item)" :class="{
              one: item.id == 1,
              two: item.id == 2,
              three: item.id == 3,
              four: item.id == 4,
              five: item.id == 5,
              six: item.id == 6,
              seven: item.id == 7,
              eight: item.id == 8,
              nine: item.id == 9,
              ten: item.id == 10,
              eleven: item.id == 11,
              twelve: item.id == 12,
              thired: item.id == 13,
              fourteen: item.id == 14,
              fifteen: item.id == 15,
              sixteem: item.id == 16,
              seventeem: item.id == 17,
              eighteen: item.id == 18,
            }">
							<div class="pic">
								<img :src="item.imgUrl" />
							</div>
							<div class="text">
								<p>{{ item.cName }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-main>
	</el-container>
</template>

<script>
	import user from "@/api/manageApi/User";
	import dataStorage from "@/../utils/dataStorage.js";
	import AccountManage from '@/api/manageApi/AccountManage'
	// 引入和风天气图标
	// npm i qweather-icons
	import 'qweather-icons/font/qweather-icons.css'
	import axios from 'axios'
	import {
		cityList
	} from '../../utils/weatherCity'
	export default {
		data() {
			return {
				year: null,
				hour: null,
				week: null,
				tumer: null,
				fullscreen: false,
				// areaCode: '101030100',//天津
				// areaCode: '101091101',//河北港口
				areaCode: '101070201',//大连
				areaList: cityList,
				weather: [],
				users: '',
				platformInfo: [],
				userMenu: [{
						id: 1,
						cName: "主页",
						eName: "show",
						isShow: false,
						path: "Show",
						imgUrl: require("../assets/img/shouye.png"),
					},
					{
						id: 2,
						cName: "告警受理",
						eName: "AlarmAcceptance",
						isShow: false,
						path: "AlarmAcceptance",
						imgUrl: require("../assets/img/sbss.png"),
					},
					{
						id: 3,
						cName: "CRT点位展示",
						eName: "CRTShow",
						isShow: false,
						path: "CRTShow",
						imgUrl: require("../assets/img/dingyue.png"),
					},
					{
						id: 4,
						cName: "一键报警",
						eName: "AKeyAlarm",
						isShow: false,
						path: "AKeyAlarm",
						imgUrl: require("../assets/img/baojin.png"),
					},
					{
						id: 5,
						cName: "设备展示",
						eName: "DeviceDisplay",
						isShow: false,
						path: "DeviceDisplay",
						imgUrl: require("../assets/img/rizhi.png"),
					},
					{
						id: 6,
						cName: "我的设备",
						eName: "deviceInfoManagement",
						isShow: false,
						path: "DeviceInfoManagement",
						imgUrl: require("../assets/img/mydevice.png"),
					},
					{
						id: 7,
						cName: "CRT管理",
						eName: "CRTManagement",
						isShow: false,
						path: "CRTManagement",
						imgUrl: require("../assets/img/crt.png"),
					},
					{
						id: 8,
						cName: "系统日志",
						eName: "history",
						isShow: false,
						path: "History",
						imgUrl: require("../assets/img/lishi.png"),
					},
					{
						id: 9,
						cName: "巡检巡查记录",
						eName: "InspectionRecord",
						isShow: false,
						path: "InspectionRecord",
						imgUrl: require("../assets/img/dingyue.png"),
					},
					{
						id: 10,
						cName: "设备设施管理",
						eName: "DeviceInfo",
						isShow: false,
						path: "DeviceInfo",
						imgUrl: require("../assets/img/sbss.png"),
					},
					{
						id: 11,
						cName: "访客记录",
						eName: "VisitorsToRecord",
						isShow: false,
						path: "VisitorsToRecord",
						imgUrl: require("../assets/img/rizhi.png"),
					},
					{
						id: 12,
						cName: "权限管理",
						eName: "JurisManagement",
						isShow: false,
						path: "JurisManagement",
						imgUrl: require("../assets/img/system.png"),
					},
					// {
					// 	id: 13,
					// 	cName: "有限空间",
					// 	eName: "WorkPlace",
					// 	isShow: false,
					// 	path: "WorkPlace",
					// 	imgUrl: require("../assets/img/kongjian.png"),
					// },
					// {
					// 	id: 14,
					// 	cName: "库存管理",
					// 	eName: "MangeDeviceCount",
					// 	isShow: false,
					// 	path: "MangeDeviceCount",
					// 	imgUrl: require("../assets/img/cangku.png"),
					// },
					// {
					// 	id: 15,
					// 	cName: "用水监控",
					// 	eName: "WaterElectricityDisplayShow",
					// 	isShow: false,
					// 	path: "WaterElectricityDisplayShow",
					// 	imgUrl: require("../assets/img/kongjian.png"),
					// },
					// {
					// 	id: 16,
					// 	cName: "用水数据",
					// 	eName: "MapEquipmentDisplay",
					// 	isShow: false,
					// 	path: "MapEquipmentDisplay",
					// 	imgUrl: require("../assets/img/cangku.png"),
					// },
					// {
					// 	id: 17,
					// 	cName: "用电数据",
					// 	eName: "ElectricityDataEntry",
					// 	isShow: false,
					// 	path: "ElectricityDataEntry",
					// 	imgUrl: require("../assets/img/cangku.png"),
					// },
					// {
					// 	id: 18,
					// 	cName: "用电监控",
					// 	eName: "ElectricityMonitoringEntry",
					// 	isShow: false,
					// 	path: "ElectricityMonitoringEntry",
					// 	imgUrl: require("../assets/img/kongjian.png"),
					// },
					// {
					// 	id: 19,
					// 	cName: "消息发布",
					// 	eName: "MessageRelease",
					// 	isShow: false,
					// 	path: "MessageRelease",
					// 	imgUrl: require("../assets/img/rizhi.png"),
					// },
				],
			};
		},
		created() {



			// 该接口一天只能请求 1000 次，超过次数后将请求不到数据，导致页面加载失败。
			this.getWeather()

			this.year = this.$moment().format("L")
			this.hour = this.$moment().format("LT")
			this.week = this.$moment().format('dddd')
			setInterval(() => {
				this.year = this.$moment().format("L")
				this.hour = this.$moment().format("LT")
				this.week = this.$moment().format('dddd')
			}, 1000);
			this.getLoginUserInfo()
		},

		mounted() {

		},

		methods: {
			city(value) {
				this.areaCode = value
				console.log(this.areaCode);
				this.getWeather()
			},

			// 查询天气预报
			getWeather() {
				axios.get(
					`https://devapi.qweather.com/v7/weather/now?location=${this.areaCode}&key=fcd952fab2824f3ead8aaa8ef20ef6c2`
					).then(res => {
					this.weather = res.data.now
				})
				console.log(this.weather);
			},


			fullScroll() {
				let element = document.documentElement;
				if (this.fullscreen) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						// IE11
						element.msRequestFullscreen();
					}
				}
				this.fullscreen = !this.fullscreen;
			},
			logout() {
				user.logout().then((res) => {});
				this.$router.replace("/");
			},

			toggle() {
				this.$router.push("/NewSelect")
			},

			myDevice(item) {
				// if (item.eName == 'show') {
				//   let path = this.$router.resolve({
				//     name: item.path,
				//     params: {
				//       msg: item.cName,
				//     },
				//   });
				//   window.open(path.href, '_blank') // 跳转新页面
				// } else {
				//   this.$router.push({
				//     name: item.path,
				//     params: {
				//       msg: item.cName,
				//     },
				//   });
				// }

				this.$router.push({
					name: item.path,
					params: {
						msg: item.cName,
					},
				});
			},
			// 修改密码
			changePasd() {
				this.$router.push("/Changepasd");
				//测试
				//this.$router.push("/Text");
			},

			getLoginUserInfo() {
				user.loginUserInfo().then((res) => {
					this.users = res.data.data.userName;

					dataStorage.setBaseData("socketUserName", res.data.data.userName);
					if (res.data.data.userName == "admin") {
						for (let i in this.userMenu) {
							this.userMenu[i].isShow = true;
						}
					} else {
						for (let i = 0; i < res.data.data.menu.list.length; i++) {
							for (let j = 0; j < this.userMenu.length; j++) {
								if (res.data.data.menu.list[i] == this.userMenu[j].eName) {
									this.userMenu[j].isShow = true;
								}
							}
						}

						// 遍历隐藏没有权限的功能模块
						for (let n = 0; n < this.userMenu.length; n++) {
							if (this.userMenu[n].isShow == false) {
								document.getElementById(n).style.display = 'none';
							}
						}
					}
					this.getTitleText()
				});
			},

			getTitleText() {
				AccountManage.getShowsByUserName(this.users).then(res => {
					this.platformInfo = res.data.data.data
					if (this.platformInfo.platformName == undefined) {
						this.platformInfo.platformName = '消防物联网监控平台';
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		height: 99%;
	}

	.companyName {
		display: flex;

		.title {
			font-size: 90px;
			color: #7ae5f9;
			font-family: "宋体";
			font-weight: 700;
			// text-align: center;
			width: 70%;
			letter-spacing: 5px;
			line-height: 250px;
			padding-left: 50px;
		}

		//   font-size: 90px;
		//   color: #7ae5f9;
		//   width: 30%;
		//   line-height: 250px;
		//   padding-left: 50px;
		//   border: 1px solid red;
		.time {
			display: flex;
			color: #7ae5f9;

			.left {
				line-height: 250px;
				font-size: 90px;
				padding: 0 30px 0 10px;
			}

			.right {
				width: 120px;
				margin-top: 90px;
				text-align: center;

				// line-height: 50px;
				.top {
					font-size: 20px;
				}

				.btm {
					margin-top: 20px;
					font-size: 20px;

				}
			}

		}
	}

	.menuList {
		width: 80%;
		margin: 80px auto 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.list-item {
			margin: 10px;
			padding: 5px;
			z-index: 1;
			width: 14%;

			.pic {
				img {
					width: 50px;
					margin: auto;
				}
			}

			.text {
				p {
					text-align: center;
					margin: 10px 0;
					font-size: 16px;
				}
			}
		}

		.disp {
			display: none;
		}

		.list-item1 {
			// border-radius: 10px;
			box-sizing: border-box;
			padding: 20px;
			cursor: pointer;
			height: 130px;
		}

		.list-item1:hover {
			background: rgba(118, 126, 119, 0.5);

			// 呼吸灯
			// animation: breathing 0.8s linear infinite alternate;
		}
	}

	.logout {
		position: absolute;
		right: 30px;
		top: 30px;
	}

	.logout img {
		width: 30px;
	}

	.logout img:hover {
		width: 40px;
		transition: 0.5s;
	}

	.header {
		width: 100%;

		.weather {
			position: absolute;
			top: 0;
			right: 10%;
			padding: 22px;
			color: #7ae5f9;
			font-size: 16px;
			letter-spacing: 1px;
			cursor: pointer;

			span {
				margin: 0 10px;
			}

			span:hover {
				display: inline-block;
				color: #a7ecf8;
				transition: 0.5s;
			}
		}


		.logout {
			position: absolute;
			right: 0px;
			top: 0px;
			padding: 20px;

			img {
				width: 30px;
			}
		}

		.logout img:hover {
			width: 40px;
			transition: 0.5s;
		}

		img {
			padding: 20px;
			width: 10%;
		}

		.toggle {
			position: absolute;
			right: 0px;
			top: 0px;
			margin: 42px 100px 0 0;
			cursor: pointer;
			font-size: 20px;
		}

		.toggle:hover {
			font-size: 22px;
			transition: 0.5s;
			color: aqua;
		}
	}

	.one {
		background: #08bdb4;
	}

	.two {
		background: #c457a2;
	}

	.three {
		background: #8d57c4;
	}

	.four {
		background: #e87352;
	}

	.five {
		background: #1b96d1;
	}

	.six {
		background: #8d57c4;
	}

	.seven {
		background: #1b96d1;
	}

	.eight {
		background: #08bdb4;
	}

	.nine {
		background: #1b96d1;
	}

	.ten {
		background: #08bdb4;
	}

	.eleven {
		background: #1b96d1;
	}

	.twelve {
		background: #8d57c4;
	}

	.thired {
		background: #6cb575;
	}

	.fourteen {
		background: #3f9e89;
	}

	.fifteen {
		background: #8d57c4;
	}

	.sixteem {
		background: #08bdb4;
	}

	.seventeem {
		background: #0ca4ca;
	}

	.eighteen {
		background: #0ca4ca;
	}

	@keyframes breathing {
		0% {
			transform: scale(0.9);
		}

		100% {
			transform: scale(1);
		}
	}

	.logout {
		position: absolute;
		right: 30px;
		top: 30px;

		.icon {
			font-size: 24px;
			color: #7ae5f9;
			cursor: pointer;
		}
	}

	.users {
		position: absolute;
		top: 22px;
		right: 80px;

		font-size: 18px;
		color: #7ae5f9;
		padding: 0px 40px 0 0;
	}

	.symbol {
		cursor: pointer;
		position: absolute;
		top: 21px;
		color: #7ae5f9;
		font-size: 20px;
		right: 80px;
		font-weight: 600;
	}

	.users::after {
		// content: "丨";
		padding-left: 20px;
	}

	/deep/.el-input__inner {
		height: 22px !important;
		border: 1px solid #067a8f;
		outline: none;
		color: #7ae5f9;
		background: rgba(0, 0, 0, 0);
	}
</style>